import React from 'react'
import { Link as GLink } from 'gatsby'
import { Link, Text } from 'theme-ui'
import TextList from '@components/TextList'
import PageTitle from '@components/PageTitle'
import { getSelectedArticleTitle } from '../../../../themes/gatsby-theme-flexiblog-personal/src/helpers/nr-postDate.helper'
import { getSelectedArticleAuthors } from '../../../../themes/gatsby-theme-flexiblog-personal/src/helpers/nr-authors.helper'

const styles = {
  item: {
    display: `inline-block`
  }
}

export const PostHead = ({ title, timeToRead, categories, readingTime,date}) => {
  const authors = getSelectedArticleTitle(title)
  const authorss = getSelectedArticleAuthors(title)
  let updatedAt = new Date(authors[0].updatedAt).toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

  const info = (
    <TextList>
      {authorss && authorss.length > 0 && (
        <>
          <Text sx={styles.item}>
            {`By `}
            {authorss.map(
              (author, index) =>
                author.slug && (
                  <>
                    <Link
                      variant='mute'
                      as={GLink}
                      to={!author.slug || `/author/${author.slug}/`}
                    >
                      <strong>{author.name}</strong>
                    </Link>
                    <span>{authorss.length - 1 !== index ? ' & ' : ''}</span>
                  </>
                )
            )}
          </Text>
        </>
      )}

      {categories && categories.length > 0 && (
        <>
          <Text sx={styles.item}>
            {` Published in  `}
            {categories.map(
              (category, index) =>
                category.slug && (
                  <>
                    <Link
                      variant='mute'
                      as={GLink}
                      to={!category.slug || `/category/${category.slug}/`}
                    >
                      <strong>{category.name}</strong>
                    </Link>
                    <span>{categories.length - 1 !== index ? ' & ' : ''}</span>
                  </>
                )
            )}
          </Text>
        </>
      )}
      {/* {category && category.slug && (
        <Text sx={styles.item}>
          {`Published in `}
          <Link variant='mute' as={GLink} to={category.slug}>
            <strong>{category.name}</strong>
          </Link>
        </Text>
      )} */}
      {<Text sx={styles.item}>{date?date:updatedAt}</Text>}
      {timeToRead && (
        <Text sx={{ ...styles.item, color: `error` }}>
          <strong>
            {readingTime && readingTime.minutes
              ? readingTime.minutes
              : timeToRead}{' '}
            min read
          </strong>
        </Text>
      )}
    </TextList>
  )

  return <PageTitle header={title} running={info} />
}
