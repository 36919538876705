import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'

export const PostBody = ({ bodyContent }) => {
  return (
    <div class="blog-body-content" dangerouslySetInnerHTML={{ __html: bodyContent }}>
    </div>

    // <MDXProvider components={components}>
    //   <MDXRenderer>{body}</MDXRenderer>
    // </MDXProvider>
  )
}
