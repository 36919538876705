import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Article from Article title ::::
export const getStrapiSeoDataFromArticle = title => {
  const { allStrapiArticle } = useStaticQuery(allStrapiArticlesSeoQuery)
  const article = allStrapiArticle.nodes.filter(node => node.title === title)
  return article[0]?.seo
}

const allStrapiArticlesSeoQuery = graphql`
  query {
    allStrapiArticle {
      nodes {
        id
        title
        seo {
          metaTitle
          metaDescription
          keywords
          metaImage {
            url
          }
        }
      }
    }
  }
`
