import React, { useEffect } from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import Categories from '@widgets/Categories'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostCommentsFacebook,
  PostCommentsGraph,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'
import { useBlogCategories } from '@helpers-blog'
import {
  getSelectedArticleAuthors,
  attachThumbnail
} from '../helpers/nr-authors.helper'
import { getStrapiArticleFromTitle } from '../helpers/nr-articles.helper'
import { getStrapiSeoDataFromArticle } from '../helpers/nr-article.seo.helper.js'
import './Post.css'
import theme from '@elegantstack/flow-ui-theme/src/theme'
import { attachCategoriesToPost, attachSeoDetails } from '@utils-blog'
import { readingTime } from 'reading-time-estimator'
const Post = ({
  data: { post, tagCategoryPosts, tagPosts, categoryPosts, previous, next },
  ...props
}) => {
  const relatedPosts = [
    ...(tagCategoryPosts ? tagCategoryPosts.nodes : []),
    ...(tagPosts ? tagPosts.nodes : []),
    ...(categoryPosts ? categoryPosts.nodes : [])
  ]
  const { pageContext: { services = {}, siteUrl } = {} } = props
  const categories = useBlogCategories()
  let authors = getSelectedArticleAuthors(post.title)

  attachCategoriesToPost(post)

  const SEO = getStrapiSeoDataFromArticle(post.title)
  let SEOData = {
    description: SEO?.metaDescription,
    title: SEO?.metaTitle,
    keywords: SEO?.keywords?.split('\n'),
    metaImage: SEO?.metaImage,
  }


  useEffect(() => {
    const { linkColor, linkHoverColor } = theme.colors
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-color', linkColor)
    document
      .querySelector(':root')
      .style.setProperty('--anchor-tag-hover-color', linkHoverColor)
  }, [])
  attachBodyContent(post)

  if (post.bodyContent) {
    const text = post.bodyContent.replace(/(<([^>]+)>)/gi, '')
    post.readingTime = readingTime(text, 220)
  }

  attachThumbnail(authors)

  return (
    <Layout {...props}>
      <Seo
        title={SEOData?.title}
        description={SEOData?.description}
        keywords={SEOData?.keywords}
        thumbnail={SEOData?.metaImage}
        timeToRead={SEOData?.timeToRead}
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...post} />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <PostImage {...post} inCard />
            <PostBody {...post} />
            <PostTagsShare {...post} location={props.location} />
            {services.disqus && <PostComments {...post} />}
            {services.graphComment && <PostCommentsGraph {...post} />}
            {services.facebookComment && (
              <PostCommentsFacebook {...post} siteUrl={siteUrl} />
            )}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
        <Sidebar>
          <AuthorCompact authors={authors} omitTitle />
          <Divider />
          <Categories categories={categories} />
          <Divider />
          {post.category && (
            <Sticky>
              <CardList
                title='Related Posts'
                nodes={relatedPosts}
                variant='horizontal-aside'
                omitMedia
                omitCategory
                limit={6}
                distinct
                aside
              />
            </Sticky>
          )}
        </Sidebar>
      </Stack>
    </Layout>
  )
}

function attachBodyContent(post) {
  let article = getStrapiArticleFromTitle(post.title)
  post['bodyContent'] = article.body.data.body
}

export default Post
