import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Article from Article title ::::
export const getStrapiArticleFromTitle = (title) => {
  const { allStrapiArticle } = useStaticQuery(allStrapiArticlesQuery);
  const article = allStrapiArticle.nodes.filter((node) => node.title === title);
  return article[0];
}

const allStrapiArticlesQuery = graphql`
query {
    allStrapiArticle {
      nodes {
        title
        body {
          data {
            body
          }
        }
        excerpt {
          data {
            excerpt
          }
        }
      }
    }
  }
`